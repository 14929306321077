import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";


import cement1 from "../images/building/cement1.png";
import cement2 from "../images/building/cement2.png";
import cement3 from "../images/building/cement3.png";
import CommonBricks from "../images/building/CommonBricks.png";
import ConcreteStones from "../images/building/ConcreteStones.png";
import Pitsand from "../images/building/Pitsand.png";
import Riversand from "../images/building/Riversand.png";

function BuildingPage() {
    return (
      <Layout>
        <SEO
          title="Building"
          keywords={[`building`, `cement`, `Zimbabwe`, `bricks`]}
        />
  
 
            <div class="rounded overflow-hidden">
              <img src={cement1}  alt="Sunset in the mountains"/>
               <div>
               <div class="font-bold text-xl mb-2">Portland Cement</div>
               <p class="text-gray-700 text-base">
               
               </p>
               </div>
               </div>
               <div class="rounded overflow-hidden">
               <img src={cement2}  alt="Sunset in the mountains"/>
               <div>
               <div class="font-bold text-xl mb-2">Supaset cement</div>
               <p class="text-gray-700 text-base">
               
               </p>
             </div>
             
            </div>
          
            <div class="rounded overflow-hidden">
              <img src={cement3}  alt="Sunset in the mountains"/>
               <div>
               <div class="font-bold text-xl mb-2">Masonry Cement</div>
               <p class="text-gray-700 text-base">
               
               </p>
             </div>
             <img src={CommonBricks}  alt="Sunset in the mountains"/>
               <div>
               <div class="font-bold text-xl mb-2">Common Bricks</div>
               <p class="text-gray-700 text-base">
              
               </p>
             </div>

             


            </div>
           
          
            <div class="rounded overflow-hidden">
              <img src={Pitsand}  alt="Sunset in the mountains"/>
               <div>
               <div class="font-bold text-xl mb-2">Pitsand</div>
               <p class="text-gray-700 text-base">
               
               </p>
               </div>
               <img src={Riversand}  alt="Sunset in the mountains"/>
               <div>
               <div class="font-bold text-xl mb-2">Riversand</div>
               <p class="text-gray-700 text-base">
               
               </p>
             </div>
             
            </div>

            <div class="rounded overflow-hidden">
              <img src={ConcreteStones}  alt="Sunset in the mountains"/>
               <div>
               <div class="font-bold text-xl mb-2">Concrete Stones</div>
               <p class="text-gray-700 text-base">
               
               </p>
               
              
             </div>
             <div>
               <div class="font-bold text-xl mb-2"></div>
               <p class="text-gray-700 text-base">
               
               </p>
             </div>
            </div>
         
            
            <h4 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Call or WhatsApp: +263 774 500 868 or +263 773 386 695
        </h4>
      
      </Layout>
    );
  }
  
  export default BuildingPage;
  